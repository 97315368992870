import { Attribution } from '../types';
import { parse, getYear, format } from 'date-fns';

export const formatTitleDate = (records: Attribution[]) => {
  let lastMonth = records[records.length - 1] ? records[records.length - 1]['Month(ISO)'] : null;

  const date = lastMonth ? parse(lastMonth, 'yyyy-MM', new Date()) : null;
  if (!date) {
    return 'this month';
  }
  const year = getYear(date);
  const monthName = format(date, 'MMMM');

  return `${monthName} ${year}`;
};
